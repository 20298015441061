(function (angular) {
    'use strict';
    var app = angular.module('app', ['ui.bootstrap', 'ui.mask', 'ngAnimate',
        'ngTouch', 'vcRecaptcha', 'bootstrapLightbox']);

    app.constant('config', {
        apiUrl: 'https://your-api.com',
        baseUrl: '/',
        enableDebug: true
    });

})(window.angular);
