(function (angular) {
    'use strict';
    angular.module('app').service('MessageService', ['$http', '$q', function ($http, $q) {
        this.sendMail = function (patient) {
            var deferred = $q.defer();

            $http.post('/messages/send-mail', patient).then(
                function successCallback(response) {
                    deferred.resolve(response.data);
                },
                function errorCallback(response) {
                    deferred.reject(response.data);
                }
            );

            return deferred.promise;
        };

        this.requestApointment = function (appointment) {
            var deferred = $q.defer();

            $http.post('/messages/send-appointment-request', appointment).then(
                function successCallback(response) {
                    deferred.resolve(response.data);
                },
                function errorCallback(response) {
                    deferred.reject(response.data);
                }
            );

            return deferred.promise;
        };
    }]);

    angular.module('app').service('ImageService', ['$http', '$q', function ($http, $q) {
        this.loadOfficeGallery = function () {
            var deferred = $q.defer();

            $http.post('/data/officeGallery.json').then(
                function successCallback(response) {
                    deferred.resolve(response.data);
                },
                function errorCallback(response) {
                    deferred.reject(response.data);
                }
            );

            return deferred.promise;
        };

    }]);
})(window.angular);