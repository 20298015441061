(function (angular) {
    'use strict';
    angular.module('app').directive('accessibleForm', function () {
        return {
            restrict: 'A',
            link: function (scope, elem) {

                // set up event handler on the form element
                elem.on('submit', function () {

                    // find the first invalid element
                    var firstInvalid = elem[0].querySelector('.ng-invalid');

                    // if we find one, set focus
                    if (firstInvalid) {
                        firstInvalid.focus();
                    }
                });
            }
        };
    });

    angular.module('app').directive('contactForm', function () {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: '/html/contactForm.html',
            controller: ['$scope', 'vcRecaptchaService', 'MessageService',
                function ($scope, vcRecaptchaService, MessageService) {
                    var vm = this;
                    $scope.response = null;
                    $scope.widgetId = null;
                    $scope.loading = false;

                    $scope.setResponse = function (response) {
                        $scope.response = response;
                    };
                    $scope.setWidgetId = function (widgetId) {
                        $scope.widgetId = widgetId;
                    };
                    $scope.cbExpiration = function () {
                        vcRecaptchaService.reload($scope.widgetId);
                        $scope.response = null;
                    };

                    $scope.submitForm = function () {
                        $scope.loading = true;

                        var qResponse = MessageService.sendMail(vm.patient);

                        qResponse.then(
                            function (response) {
                                vm.patient = {
                                    name: null,
                                    email: null,
                                    phone: null,
                                    message: null
                                };

                                $scope.contactForm.$setPristine();
                                $scope.contactForm.$setUntouched();
                                $scope.contactForm.$submitted = false;

                                alert(response.message);
                                $scope.loading = false;
                                vcRecaptchaService.reload($scope.widgetId);
                                $scope.response = null;
                            },
                            function (response) {
                                alert(response.message);
                                $scope.loading = false;
                                vcRecaptchaService.reload($scope.widgetId);
                                $scope.response = null;
                            }
                        );
                    };

                }],
            controllerAs: 'vm'
        };
    });

})(window.angular);